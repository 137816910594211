/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { CustomerState } from '@/store/customer/types';
const namespace: string = 'customer';

export default class BillFormedPage extends Vue {
	@State('customer') customer: CustomerState | undefined;
	@Action('storeBill', {namespace}) storeBill: any;
	@Getter('getBalanceReplenishment', {namespace}) getBalanceReplenishment: any;
	@Getter('getTypeWallet', {namespace}) getTypeWallet: any;
	@Getter('getTypeOfCrypto', {namespace}) getTypeOfCrypto: any;

	onBack() {
		this.$router.push({name: 'payment-method'}).then(r => r);
	}

	onSubmit() {
		this.storeBill({
			typePayment: this.getTypeWallet || this.getTypeOfCrypto,
			transaction: '00000000-0000-0000-0000-000000000000',
			paySum: this.getBalanceReplenishment,
			commission: '0'
		})
	}
}
